<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo-icon />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">الصفحة غير موجودة 🕵🏻‍♀️</h2>
        <p class="mb-2">أُووبس! 😖 لم يتم العثور على عنوان URL المطلوب على هذا الخادم.</p>

        <b-button
          style="background-color: #46b090 !important; border-color: #46b090 !important"
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          العودة إلى الرئيسية
        </b-button>

        <!-- image -->
        <component :is="imgUrl"></component>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: "error-icon",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = "error-dark-icon";
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/pages/page-misc.scss";
</style>
